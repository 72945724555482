import * as firebase from 'firebase';

const config = {
    apiKey: "AIzaSyApub-Wt03k95L6pYj1w_1H-hPB9FwWF2Y",
    authDomain: "polls-e2c91.firebaseapp.com",
    databaseURL: "https://polls-e2c91.firebaseio.com",
    projectId: "polls-e2c91",
    storageBucket: "polls-e2c91.appspot.com",
    messagingSenderId: "68516709023",
    appId: "1:68516709023:web:f01f2d30aba3336dab58aa",
    measurementId: "G-EMMMXMY610"
};

export const firebaseApp = firebase.initializeApp(config);
