import React from 'react';
import { firebaseApp } from '../utils/firebase';
import Cookies from 'universal-cookie';


import Snackbar from 'material-ui/Snackbar';
import Loading from './Loading';
import './Poll.css'
// const cTable = require('console.table');


class Embed extends React.Component {
    constructor(props) {
        super(props);
        // var voted = false;
        // var vote = this.getCookie(this.props.params.pollId);
        // if (vote !== "") {
        //     voted = true;
        // } else {
        //     voted = false;
        // }
        this.state = {
            title: '',
            options: [], //of the form [{'some option': 34}]
            voted: localStorage.getItem(this.props.params.pollId) ? true : false,
            // voted: voted,
            showSnackbar: false,
            loading: true,
            pollResults: []
        };
    }
    getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
    componentWillMount() {
        this.pollRef = firebaseApp.database().ref(`polls/${this.props.params.pollId}`);
        this.pollRef.on('value', ((snapshot) => {
            const dbPoll = snapshot.val();

            const options = Object.keys(dbPoll).reduce((a, key) => {
                if (key !== 'title') {
                    a.push({ [key]: dbPoll[key] }); //[key] is an es6 computed property name
                }
                return a;
            }, []);

            this.setState({ title: dbPoll.title, options: options, loading: false })
        })).bind(this);
    }

    componentWillUnmount() {
        this.pollRef.off();
    }

    handleVote(option) {
        let currentCount = this.state.options.filter(o => {
            return o.hasOwnProperty(option);
        })[0][option];

        firebaseApp.database().ref().update({ [`polls/${this.props.params.pollId}/${option}`]: currentCount += 1 })
        // const cookies = new Cookies();
        // cookies.set(this.props.params.pollId, true);
        localStorage.setItem(this.props.params.pollId, 'true');
        this.setState({ voted: true, showSnackbar: true });

    }
    calculatePercent(value, total) {
        var percent = (value / total) * 100;
        return Math.round(percent);
    }

    render() {
        const data = this.state.options.map(option => {
            return [Object.keys(option)[0], option[Object.keys(option)[0]]];
        });
        let { voted } = this.state;
        const renderAction = () => {
            if (voted) {
                return resultsUI
            } else {
                return optionsUI
            }
        }

        let optionsUI = this.state.options.map(option => {
            return (
                <div key={Object.keys(option)[0]} className="btn">

                    <button onClick={() => this.handleVote(Object.keys(option)[0])}>{Object.keys(option)[0]}</button>

                </div>
            );
        });
        var string = data + '';
        var pieces = string.split(",");
        let total = parseInt(pieces[1]) + parseInt(pieces[3]);
        let one = this.calculatePercent(pieces[1], total) || 0;
        let two = 100 - one || 0;
        // console.log('total :' + total);
        let totalResults = <span className="metatotal">Total Votes: {total || "0"}</span>;
        
        let resultsUI;
        resultsUI = <div><div className={"pie-chart progress-" + one || 0}><div className='radial-inner-bg'><span className="meta">{one || 0}%</span><small>{pieces[0]}</small></div></div><div className={"pie-chart progress-" + two || 0}><div className='radial-inner-bg'><span className="meta">{two || 0}%</span><small>{pieces[2]}</small></div></div></div>;

        let twitterShare;
        twitterShare = "https://twitter.com/intent/tweet?hashtags=PollOfDay&via=TheStarKenya&text=" + encodeURIComponent(this.state.title) + " Answer " + pieces[2] + " or " +pieces[0] + ", Share what you think => https://the-star.co.ke/%23polls";
        let whatsappShare;
        whatsappShare = "https://api.whatsapp.com/send?text=" + encodeURIComponent(this.state.title) + " Answer " + pieces[2] + " or " +pieces[0] + ", Share what you think => https://the-star.co.ke/%23polls";
        let facebookShare;
        facebookShare = "https://www.facebook.com/dialog/share?hashtags=PollOfDay&app_id=421376761970105&display=popup&href=https%3A%2F%2Fthe-star.co.ke%2F%23polls&redirect_uri=https%3A%2F%2Fthe-star.co.ke%2F%23polls&quote=" + encodeURIComponent(this.state.title) + " Answer " + pieces[2] + " or " +pieces[0];
        return (
            <div className='slide'>
                <div className='overlay'>
                    <img className="branding" src='https://www.the-star.co.ke/favicon.png' alt='The Star, Kenya'/>
                    <Loading loading={this.state.loading} />
                    <h3 className="question">{this.state.title}</h3>

                    {renderAction()}

                    {totalResults}
                    <div className="social">
                    <a target="popup" href={twitterShare} title="Share on Twitter">
                        <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="20px" height="20px">
                            <path 
                                fill="none" 
                                stroke="#fff" 
                                strokeLinecap="round" 
                                strokeLinejoin="round" 
                                strokeMiterlimit="10" 
                                strokeWidth="3" 
                                d="M49,11.096c-1.768,0.784-3.664,1.311-5.658,1.552c2.035-1.22,3.597-3.151,4.332-5.448c-1.903,1.127-4.013,1.947-6.255,2.388c-1.795-1.916-4.354-3.11-7.186-3.11c-5.44,0-9.849,4.409-9.849,9.847c0,0.771,0.088,1.522,0.257,2.244c-8.184-0.412-15.441-4.332-20.299-10.29c-0.848,1.458-1.332,3.149-1.332,4.953c0,3.416,1.735,6.429,4.38,8.197c-1.616-0.051-3.132-0.495-4.46-1.233c0,0.042,0,0.082,0,0.125c0,4.773,3.394,8.748,7.896,9.657c-0.824,0.227-1.694,0.346-2.592,0.346c-0.636,0-1.253-0.062-1.856-0.178c1.257,3.909,4.892,6.761,9.201,6.84c-3.368,2.641-7.614,4.213-12.23,4.213c-0.797,0-1.579-0.044-2.348-0.137c4.356,2.795,9.534,4.425,15.095,4.425c18.114,0,28.022-15.007,28.022-28.016c0-0.429-0.011-0.856-0.029-1.275C46.012,14.807,47.681,13.071,49,11.096z"/>
                        </svg>
                    </a>
                    <a target="popup" href={facebookShare} title="Share on Facebook">
                    <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="20px" height="20px">
                        <path 
                            fill="none" 
                            stroke="#fff" 
                            strokeLinejoin="round" 
                            strokeMiterlimit="10" 
                            strokeWidth="3" 
                            d="M31,11h6V3.346C36.284,3.251,32.999,3,30.141,3C24,3,20,6.68,20,13.368V19h-7v8h7v20h8V27h8l1-8h-9v-5C28,12.343,29.343,11,31,11z"
                        />
                    </svg>
                    </a>
                    <a target="popup" href={whatsappShare} data-action="share/whatsapp/share" title="Share on WhatsApp">
                    <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="20px" height="20px">
                        <path 
                            fill="none" 
                            stroke="#fff" 
                            strokeMiterlimit="10" 
                            strokeWidth="3" 
                            d="M19.102,15.022c-0.479-1.137-0.966-0.983-1.329-1.001C17.429,14.005,17.036,14,16.642,14c-0.394,0-1.033,0.146-1.575,0.73S13,16.724,13,19.594s2.116,5.641,2.411,6.031c0.296,0.389,4.164,6.278,10.087,8.804c1.409,0.6,2.509,0.959,3.366,1.228c1.414,0.444,2.702,0.381,3.72,0.231c1.134-0.167,3.494-1.411,3.986-2.772c0.492-1.362,0.492-2.529,0.344-2.772c-0.148-0.243-0.542-0.389-1.132-0.681c-0.59-0.292-3.494-1.703-4.036-1.896c-0.542-0.195-0.935-0.292-1.329,0.292c-0.394,0.584-1.525,1.896-1.869,2.286c-0.344,0.389-0.689,0.438-1.279,0.146c-0.59-0.292-2.494-0.907-4.748-2.894c-1.756-1.546-2.941-3.455-3.285-4.039s-0.037-0.899,0.259-1.19c0.266-0.262,0.59-0.681,0.886-1.022c0.296-0.341,0.394-0.584,0.59-0.973s0.098-0.73-0.05-1.022C20.775,19.059,19.594,16.19,19.102,15.022z"
                        />
                        <path 
                            fill="none" 
                            stroke="#fff" 
                            strokeLinejoin="round" 
                            strokeMiterlimit="10" 
                            strokeWidth="3" 
                            d="M25,3C12.85,3,3,12.85,3,25c0,4.072,1.111,7.884,3.04,11.155L3,47l11.274-2.793C17.448,45.983,21.104,47,25,47c12.15,0,22-9.85,22-22S37.15,3,25,3z"
                        />
                    </svg>
                    </a>
                    </div>

                </div>
                {/* <Snackbar
                    open={this.state.showSnackbar}
                    message="Thank you for your vote!"
                    autoHideDuration={4000}
                /> */}
            </div>
        );
    }
}

export default Embed;
